import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useWindowResize } from 'beautiful-react-hooks';
// components
import { SimpleLogo } from '../../atoms/logos';
import ProfileDropDown from '../profile';
import NotificationContainer from './notification';
// actions
import mainMenuAction from '../../../redux/actions/main_menu';
import accountAction from 'redux/actions/account';
// icons
// import { ReactComponent as IconMenu } from '../../../assets/image/headers/svg/menu.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/image/slider/back-icon-blue.svg';
// styles
import './style.scss';
import appAction from 'redux/actions/app';

const MainHeader: React.FC = () => {
  const notifications: any = useSelector((state: any) => state.account.notification);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(accountAction.checkNotifications());
    dispatch(accountAction.getNotifications());
    dispatch(appAction.connectToForum());
  }, []);

  return (
    <header className="main-header">
      <div className="main-header__logo-container">
        <SimpleLogo click={true} />
      </div>
      <div className="main-header__main-container">
        <div className="main-header__column">
          <HeaderButton />
        </div>
        <div className="main-header__account">
          {/*<NotificationContainer notifications={notifications} />*/}
          <div className="main-header__column">
            <ProfileDropDown />
          </div>
        </div>
      </div>
    </header>
  );
};

export default MainHeader;

const HeaderButton: React.FC = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const showMinimal = useSelector((state: any) => state.main_menu.showMinimal, shallowEqual);
  const showDrawer = useSelector((state: any) => state.main_menu.showDrawer);

  useWindowResize(() => {
    setWidth(window.innerWidth);

    if (window.innerWidth < 768) {
      dispatch(mainMenuAction.showMinimal(false));
    }
  });

  return (
    <div
      className="header-btn-wrap"
      onClick={() =>
        width > 768
          ? dispatch(mainMenuAction.showMinimal(!showMinimal))
          : dispatch(mainMenuAction.showDrawer(!showDrawer))
      }
    >
      <ArrowIcon />
    </div>
  );
};
