import React, { useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MainTemplate from '../../components/templates/mainTemplate';
import { BodyPageContainer, HeaderPageContainer } from '../../components/containers/pageContainer';
import VideoApp from '../../components/video/videoApp';
import { useAppState } from '../../state';

const CommunitySessionPage: React.FC = ({ computedMatch }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setMeetingType } = useAppState();

  useLayoutEffect(() => {
    console.log('community page useLayoutEffect');
    setMeetingType('community');
  }, []);

  console.log('community page render');
  return <VideoApp />;
};

export default CommunitySessionPage;
