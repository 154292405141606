import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
// icons
// actions
import appAction from '../../../redux/actions/app';
// types
import { stateType } from '../../../types/types';
// style
import './style.scss';
// pictures
import waitConnectPicture from '../../../assets/image/forum/wait-for-connect.png';
import eventAnalytics from 'redux/actions/analytics';

const ForumInitContainer: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const url: any = useSelector((state: stateType) => state.app.url, shallowEqual);
  const username: any = useSelector((state: stateType) => state.user.profile.username, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    !url && action();
  }, []);

  useEffect(() => {
    url && open();
  }, []);

  const action = () => {
    setLoading(true);
    dispatch(eventAnalytics.analytics('forum-button'));
    dispatch(appAction.connectToForum(() => setLoading(false)));
  };

  const open = () => {
    setLoading(false);
    window.open(url, '_blank');
  };

  const click = () => {
    url && open();
    !url && action();
  };

  return (
    <Spin spinning={loading}>
      <div className="forum-init-container">
        <h2 className="forum-init-container__header">Forum</h2>
        <div className="forum-init-container__connect-to-forum">
          <h2 className="forum-init-container__username">{username}!</h2>
          <img src={waitConnectPicture} alt="forum-init-container__wait-connect-picture" />
          <p className="forum-init-container__forum-description">
            Our community forum will launch in a separate web browser, where you can <br />
            discuss about all topics mental health related and interact with the <br />
            Grouport Community.
          </p>
          <button className="btn-form" onClick={click}>
            Connect to forum
          </button>
        </div>
      </div>
    </Spin>
  );
};

export default ForumInitContainer;
