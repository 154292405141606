import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import Menu from '../MenuBar/Menu/Menu';
import { displayName } from '../Helpers/helpers';
import LeaveCallButton from '../Buttons/LeaveCallButton/LeaveCallButton';
import EndCallDialog from './EndCallDialog/EndCallDialog';
import useRoomInfoContext from '../../../hooks/useRoomInfoContext/useRoomInfoContext';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: `${theme.palette.background.default}`,
    padding: '15px 32px 18px',
    height: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  roomName: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
  },
  endCallButton: {
    height: '44px',
    width: '135px',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    padding: '0 0.6em',
    margin: '0 0.3em',
    color: '#fff',
    backgroundColor: '#E19D89',
    '&:hover': {
      backgroundColor: 'rgba(249, 112, 102, 1)',
    },
  },
  settingsButton: {
    [theme.breakpoints.down('sm')]: {
      height: '28px',
      minWidth: '28px',
      border: '1px solid rgb(136, 140, 142)',
      padding: 0,
      margin: '0 1em',
    },
  },
}));

export default function MobileTopMenuBar() {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { roomName } = useRoomInfoContext();
  const [showEndDialog, setShowEndDialog] = useState(false);

  return (
    <Grid container alignItems="center" justifyContent="space-between" className={classes.container}>
      <img src={'/assets/groupon-logo-1.svg'} />
      <div>{roomName && roomName}</div>
      <div>
        <Button
          className={classes.endCallButton}
          onClick={() => {
            setShowEndDialog(true);
          }}
        >
          Leave session
        </Button>
        <EndCallDialog
          open={showEndDialog}
          onClose={() => {
            setShowEndDialog(false);
          }}
        />
      </div>
    </Grid>
  );
}
