import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import { shallowEqual, useSelector } from 'react-redux';
import useParticipants from '../../../../hooks/useParticipants/useParticipants';
import useUserDataContext from '../../../../hooks/useUserDataContext/useUserDataContext';
import { _extLog } from '../../../../tools/ExternalLogs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      backgroundColor: '#E19D89',
      '&:hover': {
        backgroundColor: 'rgba(249, 112, 102, 1)',
      },
    },
  }),
);

export default function LeaveCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room, localTracks } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const role = useSelector((state: any) => state.user.profile.role, shallowEqual);
  const [showButton, setShowButton] = useState(false);

  const participants = useParticipants();
  const { getUserData } = useUserDataContext();

  const handleClick = useCallback(() => {
    const level = localParticipant.networkQualityStats?.level;
    const endpoint = process.env.REACT_APP_API_URL + 'twilio/end_session';
    const JWTtoken = localStorage.getItem('token');

    _extLog(`LeaveCallButton. Room disconnected. Twilio Room SID: ${room?.sid}`);

    fetch(endpoint, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${JWTtoken}`,
      },
      body: JSON.stringify({
        room_name: room?.name,
        level: level,
        reason: 'leave-button-pressed',
      }),
    });

    room!.disconnect();
  }, [room]);

  useEffect(() => {
    if (role == 'CUSTOMER') {
      setShowButton(true);
    } else {
      let show = false;
      participants.forEach((participant) => {
        if (participant.identity != localParticipant.identity && getUserData(participant.identity)?.isHost) {
          show = true;
        }
      });
      setShowButton(show);
    }
  }, [participants, role, getUserData]);

  return showButton ? (
    <Button onClick={handleClick} className={clsx(classes.button, props.className)} data-cy-disconnect>
      Leave Session
    </Button>
  ) : null;
}
