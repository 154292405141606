import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconClock } from '../../../assets/image/common/clock-outline.svg';

import './styles.scss';
import accountAction from 'redux/actions/account';
import { stateType } from 'types/types';
import appAction from 'redux/actions/app';

const NotificationList: React.FC = () => {
  const notifications: any = useSelector((state: any) => state.account.notification);
  const dispatch = useDispatch();

  const filtered =
    notifications?.items?.reduce((acc: any, obj: any) => {
      const date = moment(obj.createdAt).format('MMM Do YY');
      if (!acc[date]) {
        acc[date] = [];
      }

      acc[date].push(obj);
      return acc;
    }, {}) || [];

  const arrays: any = Object.values(filtered);

  const sendIds = notifications?.items?.filter((item: any, index: number) => item.read === false);
  const data = sendIds?.map((el: any) => el.id);

  const newNotifications = notifications?.items?.filter((el: any) => el.read !== true);
  const oldNotifications = arrays?.filter((el: any) => el.read === true);

  useEffect(() => {
    if (data && data?.length !== 0) {
      dispatch(accountAction.readNotifications({ forumNotificationIds: data }));
    }
  });

  const url: any = useSelector((state: stateType) => state.app.url, shallowEqual);

  const openUrl = (item: any) => {
    const searchString = '?grouport_token=';
    const startIndex = url?.indexOf(searchString);
    const resultString = url?.substring(startIndex);
    window.open(`${item.referenceUrl}${resultString}`, '_blank');
  };

  return (
    <div
      className="notification-list-container"
      style={{ background: notifications?.items?.length !== 0 ? '#fff' : 'transparent' }}
    >
      <div>
        <div className="notification-menu__section">
          <div className="notification-list-container__list-item-container">
            {newNotifications?.length !== 0 && (
              <div className="notification-menu__section-title notification-list-container__title">UNREAD</div>
            )}
            {newNotifications?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <div
                    key={index}
                    className={`notification-menu__new-notif ${
                      item.read
                        ? 'notification-list-container__list-item notification-list-container__list-item-old'
                        : 'notification-list-container__list-item'
                    }`}
                  >
                    <div className="notification-list-container__section-message">
                      <div className="notification-list-container__message" onClick={() => openUrl(item)}>
                        {item.message}
                      </div>
                      <div className="notification-list-container__time">{moment(item.createdAt).format('LT')}</div>
                    </div>
                    <div>
                      <div className="notification-menu__section-time">
                        <IconClock />
                        <div className="notification-menu__section-time-text">
                          {moment(item.createdAt).format('DD MMM YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {arrays?.map((items: any, index: number) => {
              return (
                <div key={index}>
                  {oldNotifications.length === 0 && (
                    <div className="notification-menu__section-title notification-list-container__title">
                      {moment(items[index - 1]?.createdAt)
                        .format('LL')
                        .toUpperCase()}
                    </div>
                  )}
                  {items.map((item: any, index: number) => {
                    if (item.read) {
                      return (
                        <div key={index}>
                          <div
                            key={index}
                            className={`notification-menu__new-notif ${
                              item.read
                                ? 'notification-list-container__list-item notification-list-container__list-item-old'
                                : 'notification-list-container__list-item'
                            }`}
                          >
                            <div className="notification-list-container__section-message">
                              <div className="notification-list-container__message" onClick={() => openUrl(item)}>
                                {item.message}
                              </div>
                              <div className="notification-list-container__time">
                                {moment(item.createdAt).format('LT')}
                              </div>
                            </div>
                            <div>
                              <div className="notification-menu__section-time">
                                <IconClock />
                                <div className="notification-menu__section-time-text">
                                  {moment(item.createdAt).format('DD MMM YYYY')}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationList;
