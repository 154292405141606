/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { call, put, select } from 'redux-saga/effects';
// api
import { api } from '../../api/app';
// actions
import appAction from '../actions/app';
// helps
import showError from './showError';
import consultationAction from '../actions/consultation';

export function* sagaGetHelpFullTips() {
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  try {
    const response = yield call(api.getHelpfulTip, role.toLowerCase());
    yield put(appAction.setHelpSlider(response.data.data.items));
  } catch (e) {
    showError();
  }
}

export function* sagaSetImage(action) {
  try {
    const response = yield call(api.file, action.data.type, action.data.data);
    yield put(appAction.setFile(response.data.data.item));
  } catch (e) {
    showError();
  }
}

export function* sagaSetImageCreateEvent(action) {
  try {
    const response = yield call(api.fileCreateEvent, action.data.data);
    yield put(appAction.setFile(response.data.data.item));
  } catch (e) {
    showError();
  }
}

export function* sagaConnectToForum(action) {
  try {
    const response = yield call(api.getUrl);
    yield put(appAction.setUrl(response.data.data.url));
  } catch (e) {
  } finally {
    action.callback && action.callback();
  }
}

export function* sagaGetAppStates(action) {
  const getRole = (state) => state.user.profile.role;
  const role = yield select(getRole);
  yield put(consultationAction.loader(true));
  try {
    const response = yield call(api.getSupportStates, role.toLowerCase());
    yield put(appAction.setSupportStates(response.data.data.items));

    action.callback && action.callback();
  } catch (e) {
    showError();
  } finally {
    yield put(consultationAction.loader(false));
  }
}
