import React, { createContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppState } from '../../../state';

type RoomInfoContextType = {
  roomType: string;
  hostFirstName: string;
  hostLastName: string;
  hostTitle: string;
  hostInitials: string;
  hostLicenses: string[];
  roomName: string;
  roomTypeName: string;
  sessionStartTime: number;
  sessionEndTime: number;
};

export const RoomInfoContext = createContext<RoomInfoContextType>(null!);

export const RoomInfoProvider: React.FC = ({ children }) => {
  const [roomType, setRoomType] = useState('session');
  const [hostFirstName, setHostFirstName] = useState('');
  const [hostLastName, setHostLastName] = useState('');
  const [hostTitle, setHostTitle] = useState('');
  const [hostInitials, setHostInitials] = useState('');
  const [hostLicenses, setHostLicences] = useState([]);
  const [roomName, setRoomName] = useState('');
  const [roomTypeName, setRoomTypeName] = useState('Session');
  const [sessionStartTime, setSessionStartTime] = useState(0);
  const [sessionEndTime, setSessionEndTime] = useState(0);
  const { meetingType } = useAppState();

  const { URLRoomName } = useParams<{ URLRoomName: string }>();

  const [lastUpdateTime, setLastUpdateTime] = useState(0);

  useEffect(() => {
    const endpoint = process.env.REACT_APP_API_URL + 'twilio/room_info';
    const JWTtoken = localStorage.getItem('token');
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${JWTtoken}`,
      },
      body: JSON.stringify({
        room_name: URLRoomName,
        room_type: meetingType,
      }),
    })
      .then((response) => response.json())
      .then((responseData) => {
        console.log(responseData);
        if (responseData.sessionFound) {
          setHostFirstName(responseData.hostFirstName);
          setHostLastName(responseData.hostLastName);
          setHostTitle(responseData.hostTitle);
          setHostInitials(responseData.hostFirstName.toUpperCase()[0] + responseData.hostLastName.toUpperCase()[0]);
          setHostLicences(responseData.hostLicenses);
          setRoomName(responseData.sessionName);
          setRoomTypeName(responseData.sessionType);
          setLastUpdateTime(Date.now());
          setSessionStartTime(responseData.sessionStartTime);
          setSessionEndTime(responseData.sessionEndTime);
        } else if (Date.now() - lastUpdateTime > 2000) {
          setHostFirstName('');
          setHostLastName('');
          setHostTitle('');
          setHostInitials('');
          setHostLicences([]);
          setRoomName('');
          setRoomTypeName('Session');
          setSessionStartTime(0);
          setSessionEndTime(0);
        }
      });
  }, [URLRoomName, meetingType]);

  return (
    <RoomInfoContext.Provider
      value={{
        roomType,
        hostFirstName,
        hostLastName,
        hostTitle,
        hostInitials,
        hostLicenses,
        roomName,
        roomTypeName,
        sessionStartTime,
        sessionEndTime,
      }}
    >
      {children}
    </RoomInfoContext.Provider>
  );
};
