import React, { FC, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import moment from 'moment-timezone';
import cn from 'classnames';
import { Spin } from 'antd';
import { useParams } from 'react-router-dom';
import { useTimeout } from 'beautiful-react-hooks';
// components
import { MembersMiniList } from '../../../cards/members';
import RescheduleSession from '../../../modals/reschedule/session';
import MoreDropdown from 'components/molecules/popovers/MoreDropdown';
import Button from 'components/atoms/form/Button';
import BeginsIn from 'components/molecules/beginsIn';
import CanselSession from '../../../modals/cansel/session';
import StatusGroups from 'components/molecules/status/groups';
// actions
import sessionsAction from 'redux/actions/sessions';
import accountAction from 'redux/actions/account';
// types
import { stateType } from 'types/types';
import { IEvent, ISessionItem } from 'types/app-types';
// helpers
import { colorGenerator, timeConvertor, week_days } from 'configs/tables/admin/clients';
import { createLicenseList, momentOrdinal } from '../../../../../tools/helpFunctions';
// icons
import Icon from '@ant-design/icons';
import { ReactComponent as IconClock } from 'assets/image/questions/clock.svg';
import skillsIcon from 'assets/image/dropdown/skills.svg';
import manIcon from 'assets/image/dropdown/man.svg';
import calendarIcon from 'assets/image/dropdown/calendar.svg';
import timeIcon from 'assets/image/dropdown/time.svg';
import mockPhoto from 'assets/image/common/mockPhoto.png';
// styles
import './style.scss';
import AddToCalendarLink from '../../../Calendar';

moment.tz.setDefault('America/New_York');

interface ISessionCard {
  item: ISessionItem;
  type?: string;
  therapistName?: string;
  hideMembers?: boolean;
  isBg?: boolean;
  isPaused?: boolean;
  coHost?: {
    id: number;
    firstname: string;
    lastname: string;
  };
}

export const SessionCard: FC<ISessionCard> = ({
  item,
  type,
  therapistName,
  hideMembers,
  isBg,
  isPaused,
}: ISessionCard) => {
  const { medicalGroup, start, end, groupSessionHistoryMarkers } = item;

  const licensesList = createLicenseList(medicalGroup?.therapist?.licenses);

  const actions = [
    {
      text: medicalGroup?.groupTopic.name,
      icon: skillsIcon,
    },
    {
      text: medicalGroup?.therapist.firstname + ' ' + medicalGroup?.therapist.lastname,
      icon: manIcon,
    },
    {
      text: moment(start).tz('America/New_York').format('dddd, MMMM Do'),
      icon: calendarIcon,
    },
    {
      text: `${moment(start).tz('America/New_York').format('h:mm A')} - ${moment(end)
        .tz('America/New_York')
        .format('h:mm A')} ET`,
      icon: timeIcon,
    },
  ];

  return (
    <div className={cn('session-item', { 'session-item_bg': isBg })}>
      <div
        className={cn('session-item__status-history', {
          rescheduled:
            groupSessionHistoryMarkers?.rescheduleSessionHistory && !groupSessionHistoryMarkers?.cancelSessionHistory,
          cancel: groupSessionHistoryMarkers?.cancelSessionHistory,
        })}
      >
        {groupSessionHistoryMarkers?.rescheduleSessionHistory &&
          !groupSessionHistoryMarkers?.cancelSessionHistory &&
          'Rescheduled'}
        {groupSessionHistoryMarkers?.cancelSessionHistory && 'Cancelled'}
      </div>
      <div className="session-item__wrap">
        <div className="session-item__avatar">
          {item.medicalGroup?.avatar ? (
            <img src={item.medicalGroup?.avatar?.url} alt="session avatar" className="session-item__avatar-img" />
          ) : (
            <div className="session-item__home-avatar-txt">{item.medicalGroup.groupTopic.name[0]}</div>
          )}
          {/*TODO add conditional and special property*/}
          {false && (
            <div className={cn('session-item__event-type', { webinar: true, session: false })}>Community Webinar</div>
          )}
        </div>
        <div className="session-item__body">
          <div style={{ position: 'absolute', top: 20, right: 20 }}>
            <MoreDropdown actions={actions} />
          </div>
          <div className="session-item__header">
            <div className="session-item__title">{item.medicalGroup?.groupTopic?.name} Group</div>
          </div>

          <div className="session-item__time-block">
            <div className="session-item__item">
              <div className="session-item__label">Date:</div>
              <div className="session-item__value">
                <Moment format="dddd, MMMM">{item.start}</Moment> <Moment format="D">{item.start}</Moment>
                <sup>{momentOrdinal(item.start)}</sup>
              </div>
            </div>

            <div className="session-item__item">
              <div className="session-item__label">Time:</div>
              <div className="session-item__value">
                <Moment format="h:mm A">{item.start}</Moment>
                {' - '}
                <Moment format="h:mm A">{item.end}</Moment> ET
              </div>
            </div>
            {item.coHost && (
              <div className="session-item__item">
                <div className="session-item__label">Co-host:</div>
                <div className="session-item__value">
                  {item.coHost?.firstname} {item.coHost?.lastname}
                </div>
              </div>
            )}
            <div>
              <AddToCalendarLink name={item.medicalGroup?.groupTopic?.name} start={item.start} end={item.end} />
            </div>
          </div>

          {!hideMembers && item.members && (
            <div className="session-item__members">
              <MembersMiniList members={item.members} />
            </div>
          )}
          {!groupSessionHistoryMarkers?.cancelSessionHistory && (
            <div className="session-item__footer footer-top">
              <div>
                <BeginsIn start={item.start} status={item.status} />
              </div>
              {type && !isPaused && (
                <SessionButton item={item} name={type === 'join' ? 'Join this session' : 'Start session'} type={type} />
              )}
            </div>
          )}
        </div>
      </div>
      {!groupSessionHistoryMarkers?.cancelSessionHistory && (
        <div className="session-item__footer footer-bottom">
          <div>
            <BeginsIn start={item.start} status={item.status} />
          </div>
          {type && !isPaused && (
            <SessionButton item={item} name={type === 'join' ? 'Join this session' : 'Start session'} type={type} />
          )}
        </div>
      )}
    </div>
  );
};

interface IEventCard {
  item: IEvent;
}

export const EventsCard: FC<IEventCard> = ({ item }: IEventCard) => {
  const { title, picture, start, end } = item;

  return (
    <div className="session-item">
      <div className="session-item__avatar">
        {picture?.url && <img src={picture?.url} alt="" className="session-item__avatar-img" />}
      </div>
      <div className="session-item__body">
        <div className="session-item__header">
          <div className="session-item__title">{title}</div>
        </div>

        <div className="session-item__row">
          <div className="session-item__column">
            <div className="session-item__item">
              <div className="session-item__label">Date:</div>
              <div className="session-item__value">
                <Moment format="dddd, MMMM Do">{start}</Moment>
              </div>
            </div>
            <div className="session-item__item">
              <div className="session-item__label">Time:</div>
              <div className="session-item__value">
                <Moment format="h:mm A">{start}</Moment>
                {' - '}
                <Moment format="h:mm A">{end}</Moment> ET
              </div>
            </div>
          </div>
          <div className="session-item__column">
            <span className="session-item__event-label">Community Event</span>
          </div>
        </div>

        <div className="session-item__footer">
          <BeginsIn start={item.start} />
          <SessionButton item={item} name={'Join this session'} type={'join'} hide={true} />
        </div>
      </div>
    </div>
  );
};

export const IntakeConsultationCard: FC = () => {
  return (
    <div className="session-item">
      Please check your email for your intake consultation appointment details. When the intake coordinator has
      confirmed your group choice, your upcoming sessions will be displayed here.
    </div>
  );
};

export const IntakeConsultationFutureCard: FC = () => {
  const item: any = useSelector((state: stateType) => state.account.info);
  const profile: any = useSelector((state: stateType) => state.user.profile);
  const loader: any = useSelector((state: stateType) => state.account.loader);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(accountAction.getInfo(profile.role));
  }, []);

  return (
    <Spin spinning={loader}>
      <div className="session-item">
        <div className="session-item__wrap">
          <div className="session-item__avatar">
            <img src={mockPhoto} alt="" className="session-item__avatar-img" />
          </div>
          <div className="session-item__body">
            <div className="session-item__header">
              <div className="session-item__title">
                {profile.preferableGroupInfo
                  ? profile.preferableGroupInfo.name
                  : `${item.medicalGroup?.groupTopic?.name} Group`}
              </div>
            </div>

            <div className="session-item__row">
              <div className="session-item__column">
                <div className="session-item__item">
                  <div className="session-item__label">Date:</div>
                  <div className="session-item__value">TBD Following Consultation</div>
                </div>
                <div className="session-item__item">
                  <div className="session-item__label">Time:</div>
                  <div className="session-item__value">TBD Following Consultation</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export const SessionItemNext: React.FC<any> = ({ item, startDate, endDate }: any) => {
  return (
    <div className="session-item">
      <div className="session-item__avatar">
        {item.medicalGroup?.avatar ? (
          <img src={item.medicalGroup?.avatar?.url} alt="" className="session-item__avatar-img" />
        ) : (
          <div className="session-item__home-avatar-txt">{item.medicalGroup.groupTopic.name[0]}</div>
        )}
      </div>
      <div className="session-item__body">
        <div className="session-item__header">
          <div className="session-item__title">{item.medicalGroup?.groupTopic?.name} Group</div>
        </div>
        <div className="session-item__item">
          <div className="session-item__label">Time:</div>
          <div className="session-item__value">
            <Moment format="h:mm A">{startDate ? startDate : item.start}</Moment>
            {' - '}
            <Moment format="h:mm A">{endDate ? endDate : item.end}</Moment> ET
          </div>
        </div>
        <div>
          <AddToCalendarLink name={item.medicalGroup?.groupTopic?.name} start={item.start} end={item.end} />
        </div>
        <BeginsIn start={startDate ? startDate : item.start} status={item.status} isAddBackground />
      </div>
    </div>
  );
};

export const AdminGroupSessionItem: React.FC<any> = ({ item }: any) => {
  const { group } = useSelector((state: stateType) => state.groups.item);

  return (
    <div className="session-item">
      <div
        className={cn('session-item__status-history', {
          rescheduled:
            item?.groupSessionHistoryMarkers?.rescheduleSessionHistory &&
            !item?.groupSessionHistoryMarkers?.cancelSessionHistory,
          cancel: item?.groupSessionHistoryMarkers?.cancelSessionHistory,
        })}
      >
        {item?.groupSessionHistoryMarkers?.rescheduleSessionHistory &&
          !item?.groupSessionHistoryMarkers?.cancelSessionHistory &&
          'Rescheduled'}
        {item?.groupSessionHistoryMarkers?.cancelSessionHistory && 'Cancelled'}
      </div>
      <div className="session-item__avatar">
        {item?.medicalGroup?.avatar ? (
          <img src={item?.medicalGroup?.avatar?.url} alt="" className="session-item__avatar-img" />
        ) : (
          <div className="session-item__home-avatar-txt">{item.medicalGroup.groupTopic.name[0]}</div>
        )}
      </div>
      <div className="session-item__body">
        <div className="session-item__header">
          <div className="session-item__title">{item.medicalGroup?.groupTopic?.name} Group</div>{' '}
          {!item?.groupSessionHistoryMarkers?.cancelSessionHistory && <CanselSession item={item} group={group} />}
        </div>

        <div className="session-item__item">
          <div className="session-item__label">Date:</div>
          <div className="session-item__value">
            <Moment format="dddd, MMMM D">{item.start}</Moment>
            <sup>{momentOrdinal(item.start)}</sup>
          </div>
        </div>

        <div className="session-item__item">
          <div className="session-item__label">Time:</div>
          <div className="session-item__value">
            <Moment format="h:mm A">{item.start}</Moment>
            {' - '}
            <Moment format="h:mm A">{item.end}</Moment> ET
          </div>
        </div>
        {item.coHost && (
          <div className="session-item__item">
            <div className="session-item__label">Co-host:</div>
            <div className="session-item__value">
              {item.coHost?.firstname} {item.coHost?.lastname}
            </div>
          </div>
        )}
        <div>
          <AddToCalendarLink name={item.medicalGroup?.groupTopic?.name} start={item.start} end={item.end} />
        </div>

        {!item?.groupSessionHistoryMarkers?.cancelSessionHistory && (
          <div className="session-item__footer">
            <BeginsIn start={item.start} status={item.status} />
            <RescheduleSession session={item} />
          </div>
        )}
      </div>
    </div>
  );
};

// THERAPISTS

export const TherapistGroupSessionItem: React.FC<any> = ({ item }: any) => {
  return (
    <div className="session-item">
      <div
        className={cn('session-item__status-history', {
          rescheduled:
            item?.groupSessionHistoryMarkers?.rescheduleSessionHistory &&
            !item?.groupSessionHistoryMarkers?.cancelSessionHistory,
          cancel: item?.groupSessionHistoryMarkers?.cancelSessionHistory,
        })}
      >
        {item?.groupSessionHistoryMarkers?.rescheduleSessionHistory &&
          !item?.groupSessionHistoryMarkers?.cancelSessionHistory &&
          'Rescheduled'}
        {item?.groupSessionHistoryMarkers?.cancelSessionHistory && 'Cancelled'}
      </div>
      <div className="session-item__avatar">
        {item?.medicalGroup?.avatar ? (
          <img src={item.medicalGroup?.avatar?.url} alt="" className="session-item__avatar-img" />
        ) : (
          <div className="session-item__home-avatar-txt">{item.medicalGroup.groupTopic.name[0]}</div>
        )}
      </div>
      <div className="session-item__body">
        <div className="session-item__header">
          <div className="session-item__title">{item.medicalGroup?.groupTopic?.name} Group</div>
        </div>

        <div className="session-item__item">
          <div className="session-item__label">Date:</div>
          <div className="session-item__value">
            <Moment format="dddd, MMMM D">{item.start}</Moment>
            <sup>{momentOrdinal(item.start)}</sup>
          </div>
        </div>

        <div className="session-item__item">
          <div className="session-item__label">Time:</div>
          <div className="session-item__value">
            <Moment format="h:mm A">{item.start}</Moment>
            {' - '}
            <Moment format="h:mm A">{item.end}</Moment> ET
          </div>
        </div>
        <div>
          <AddToCalendarLink name={item.medicalGroup?.groupTopic?.name} start={item.start} end={item.end} />
        </div>
        {!item?.groupSessionHistoryMarkers?.cancelSessionHistory && (
          <div className="session-item__footer">
            <BeginsIn start={item.start} status={item.status} />
            <SessionButton item={item} name={'Start Session'} type={'start'} isInGroup />
          </div>
        )}
      </div>
    </div>
  );
};

export const TherapistGroupDetailsCard: React.FC<any> = ({ item, members }: any) => {
  return (
    <div className="session-item">
      <div className="session-item__avatar">
        {item.avatar ? (
          <img src={item?.avatar?.url} alt="" className="session-item__avatar-img" />
        ) : (
          <div className="session-item__home-avatar-txt">{item.name[0]}</div>
        )}
      </div>
      <div className="session-item__body">
        <div className="session-item__header">
          <div className="session-item__title">{item.name} Group</div>
        </div>

        <div className="session-item__item">
          <div className="session-item__label">Date:</div>
          <div className="session-item__value">
            {/*<Moment format="dddd">{item.start}</Moment>s*/}
            {week_days[item.date.dayOfWeek - 1]}s
          </div>
        </div>

        <div className="session-item__item">
          <div className="session-item__label">Time:</div>
          <div className="session-item__value">
            {timeConvertor(item.date.start)} - {timeConvertor(item.date.end, 'type-time')} ET
          </div>
        </div>
        <div className="session-item__footer">
          <div className="session-item__members">{members && <MembersMiniList members={members} />}</div>
        </div>
      </div>
    </div>
  );
};

export const TherapistSessionHistoryItem: React.FC<any> = ({ item }: any) => {
  return (
    <div className="session-item">
      <div className="session-item__avatar">
        {item.medicalGroup?.avatar ? (
          <img src={item.medicalGroup?.avatar.url} alt="" className="session-item__avatar-img" />
        ) : (
          <div className="session-item__home-avatar-txt">{item.medicalGroup.groupTopic.name[0]}</div>
        )}
      </div>
      <div className="session-item__body">
        <div className="session-item__header">
          <div className="session-item__title">{item.medicalGroup?.groupTopic?.name} Group</div>
        </div>

        <div className="session-item__item">
          <div className="session-item__label">Date:</div>
          <div className="session-item__value">
            <Moment format="dddd, MMMM D">{item.start}</Moment>
            <sup>{momentOrdinal(item.start)}</sup>
          </div>
        </div>

        <div className="session-item__item">
          <div className="session-item__label">Time:</div>
          <div className="session-item__value">
            <Moment format="h:mm A">{item.start}</Moment>
            {' - '}
            <Moment format="h:mm A">{item.end}</Moment> ET
          </div>
        </div>
        <div className="session-item__footer">
          <div className="session-item__members">
            <div className="session-item__label">
              {' '}
              {item.attendedMembersCount}/{item.membersCount} Members:
            </div>
            <MembersMiniList
              members={item?.attendedMembers.map((item: any) => {
                return {
                  id: item.id,
                  firstname: item.firstname,
                  lastname: item.lastname,
                  color: colorGenerator(),
                };
              })}
            />
          </div>
          <StatusGroups status={item.status} />
        </div>
      </div>
    </div>
  );
};

export const TherapistHomeSessionItemNext: React.FC<any> = ({ item }: any) => {
  return (
    <div className="session-item">
      <div className="session-item__avatar">
        {item?.medicalGroup?.avatar ? (
          <img src={item.medicalGroup?.avatar?.url} alt="" className="session-item__avatar-img" />
        ) : (
          <div className="session-item__home-avatar-txt">{item.medicalGroup.groupTopic.name[0]}</div>
        )}
      </div>
      <div className="session-item__body">
        <div className="session-item__header">
          <div className="session-item__title">{item.medicalGroup?.groupTopic?.name} Group</div>
        </div>

        <div className="session-item__item">
          <div className="session-item__label">Date:</div>
          <div className="session-item__value">
            <Moment format="dddd, MMMM D">{item.start}</Moment>
            <sup>{momentOrdinal(item.start)}</sup>
          </div>
        </div>

        <div className="session-item__item">
          <div className="session-item__label">Time:</div>
          <div className="session-item__value">
            <Moment format="h:mm A">{item.start}</Moment>
            {' - '}
            <Moment format="h:mm A">{item.end}</Moment> ET
          </div>
        </div>
        <div className="session-item__block">
          <div className="session-item__column">
            <div className="session-item__members">
              <div className="session-item__label">Members:</div>
              <MembersMiniList members={item.members} />
            </div>
          </div>
          <div className="session-item__column session-item__column-end">
            <div className="session-item__begin">
              <Icon component={IconClock} />
              <BeginsIn start={item.start} status={item.status} />
            </div>
            <SessionButton item={item} name={'Start Session'} type={'start'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const TherapistHomeSessionItemCoHosted: React.FC<any> = ({ item }: any) => {
  const { start, end, medicalGroup } = item;

  const actions = [
    {
      text: medicalGroup?.groupTopic.name,
      icon: skillsIcon,
    },
    {
      text: medicalGroup?.therapist.firstname + ' ' + medicalGroup?.therapist.lastname,
      icon: manIcon,
    },
    {
      text: moment(start).tz('America/New_York').format('dddd, MMMM Do'),
      icon: calendarIcon,
    },
    {
      text: `${moment(start).tz('America/New_York').format('h:mm A')} - ${moment(end)
        .tz('America/New_York')
        .format('h:mm A')} ET`,
      icon: timeIcon,
    },
  ];

  return (
    <div className="session-item session-item_bg">
      <div
        className={cn('session-item__status-history', {
          rescheduled:
            item?.groupSessionHistoryMarkers?.rescheduleSessionHistory &&
            !item?.groupSessionHistoryMarkers?.cancelSessionHistory,
          cancel: item?.groupSessionHistoryMarkers?.cancelSessionHistory,
        })}
      >
        {item?.groupSessionHistoryMarkers?.rescheduleSessionHistory &&
          !item?.groupSessionHistoryMarkers?.cancelSessionHistory &&
          'Rescheduled'}
        {item?.groupSessionHistoryMarkers?.cancelSessionHistory && 'Cancelled'}
      </div>
      <div className="session-item__avatar">
        {item.medicalGroup?.avatar ? (
          <img src={item.medicalGroup?.avatar?.url} alt="" className="session-item__avatar-img" />
        ) : (
          <div className="session-item__home-avatar-txt">{item.medicalGroup.groupTopic.name[0]}</div>
        )}
      </div>
      <div className="session-item__body">
        <div style={{ position: 'absolute', top: 20, right: 20 }}>
          <MoreDropdown actions={actions} />
        </div>
        <div className="session-item__header">
          <div className="session-item__title">{item.medicalGroup?.groupTopic?.name} Group</div>
        </div>

        <div className="session-item__item">
          <div className="session-item__label">Date:</div>
          <div className="session-item__value">
            <Moment format="dddd, MMMM D">{item.start}</Moment>
            <sup>{momentOrdinal(item.start)}</sup>
          </div>
        </div>

        <div className="session-item__item">
          <div className="session-item__label">Time:</div>
          <div className="session-item__value">
            <Moment format="h:mm A">{item.start}</Moment>
            {' - '}
            <Moment format="h:mm A">{item.end}</Moment> ET
          </div>
        </div>
        <div className="session-item__item">
          <div className="session-item__label">Group Host:</div>
          <div className="session-item__value">
            {item.medicalGroup?.therapist?.firstname} {item.medicalGroup?.therapist?.lastname}
          </div>
        </div>
        {item?.members && (
          <div className="session-item__members session-item__members_future">
            <MembersMiniList
              members={item.members.map((item: any) => {
                return {
                  id: item.id,
                  firstname: item.firstname,
                  lastname: item.lastname,
                  color: colorGenerator(),
                  clientId: item.id,
                };
              })}
            />
          </div>
        )}
        <div>
          <AddToCalendarLink name={item.medicalGroup?.groupTopic?.name} start={item.start} end={item.end} />
        </div>
        <div className="session-item__item session-item__item_last">
          <BeginsIn start={item.start} status={item.status} />
          <div style={{ width: 160, marginLeft: 7 }}>
            <SessionButton item={item} name={'Start Session'} type={'start'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const TherapistHomeSessionItemFuture: React.FC<any> = ({ item }: any) => {
  const { start, end, medicalGroup } = item;

  const actions = [
    {
      text: medicalGroup?.groupTopic.name,
      icon: skillsIcon,
    },
    {
      text: medicalGroup?.therapist.firstname + ' ' + medicalGroup?.therapist.lastname,
      icon: manIcon,
    },
    {
      text: moment(start).tz('America/New_York').format('dddd, MMMM Do'),
      icon: calendarIcon,
    },
    {
      text: `${moment(start).tz('America/New_York').format('h:mm A')} - ${moment(end)
        .tz('America/New_York')
        .format('h:mm A')} ET`,
      icon: timeIcon,
    },
  ];

  return (
    <div className="session-item session-item_bg">
      <div
        className={cn('session-item__status-history', {
          rescheduled:
            item?.groupSessionHistoryMarkers?.rescheduleSessionHistory &&
            !item?.groupSessionHistoryMarkers?.cancelSessionHistory,
          cancel: item?.groupSessionHistoryMarkers?.cancelSessionHistory,
        })}
      >
        {item?.groupSessionHistoryMarkers?.rescheduleSessionHistory &&
          !item?.groupSessionHistoryMarkers?.cancelSessionHistory &&
          'Rescheduled'}
        {item?.groupSessionHistoryMarkers?.cancelSessionHistory && 'Cancelled'}
      </div>
      <div className="session-item__avatar">
        {item.medicalGroup?.avatar ? (
          <img src={item.medicalGroup?.avatar?.url} alt="" className="session-item__avatar-img" />
        ) : (
          <div className="session-item__home-avatar-txt">{item.medicalGroup.groupTopic.name[0]}</div>
        )}
      </div>
      <div className="session-item__body">
        <div style={{ position: 'absolute', top: 20, right: 20 }}>
          <MoreDropdown actions={actions} />
        </div>
        <div className="session-item__header">
          <div className="session-item__title">{item.medicalGroup?.groupTopic?.name} Group</div>
        </div>

        <div className="session-item__item">
          <div className="session-item__label">Date:</div>
          <div className="session-item__value">
            <Moment format="dddd, MMMM D">{item.start}</Moment>
            <sup>{momentOrdinal(item.start)}</sup>
          </div>
        </div>

        <div className="session-item__item">
          <div className="session-item__label">Time:</div>
          <div className="session-item__value">
            <Moment format="h:mm A">{item.start}</Moment>
            {' - '}
            <Moment format="h:mm A">{item.end}</Moment> ET
          </div>
        </div>
        {item?.members && (
          <div className="session-item__members session-item__members_future">
            <MembersMiniList
              members={item.members.map((item: any) => {
                return {
                  id: item.id,
                  firstname: item.firstname,
                  lastname: item.lastname,
                  color: colorGenerator(),
                  clientId: item.id,
                };
              })}
            />
          </div>
        )}
        <div>
          <AddToCalendarLink name={item.medicalGroup?.groupTopic?.name} start={item.start} end={item.end} />
        </div>
        <div className="session-item__item session-item__item_last">
          <BeginsIn start={item.start} status={item.status} />
        </div>
      </div>
    </div>
  );
};

export const SessionButton = ({ item, name, type, isInGroup }: any) => {
  const [disabled, setDisabled] = useState(true);
  const [diffTime, setDiffTime] = useState(0);
  const { role, status }: any = useSelector((state: stateType) => state.user.profile, shallowEqual);

  const dispatch = useDispatch();
  const params = useParams<{ id: string }>();

  const { id, startUrl, joinUrl, canJoinZoom, twilioRoomName, start } = item;

  useEffect(() => {
    setDiffTime(moment(item.start).subtract(24, 'minute').diff(moment()));
    console.log('Diff timeout: ', moment(item.start).subtract(14, 'minute').diff(moment()));
  }, []);

  useTimeout(() => {
    console.log('Timeout acivated', role, diffTime, twilioRoomName);
    if (role === 'THERAPIST' && diffTime > 0 && (startUrl || twilioRoomName)) {
      setDisabled(false);
      if (isInGroup) {
        dispatch(sessionsAction.getNext(params?.id));
      } else {
        dispatch(sessionsAction.getSelfNext());
      }
    }

    if (role === 'CUSTOMER' && status.name === 'active' && diffTime > 0 && (joinUrl || twilioRoomName)) {
      setDisabled(false);
      dispatch(sessionsAction.getSelfNext());
    }
  }, diffTime);

  const startTimestamp = moment(start).tz('America/New_York', true).format('x');

  useEffect(() => {
    const result = +startTimestamp - new Date().getTime();
    const days = result / 86400000;
    const hours = moment(start).tz('America/New_York', true).diff(moment().tz('America/New_York'), 'hours');
    const minutes = moment(start).tz('America/New_York', true).diff(moment().tz('America/New_York'), 'minutes');
    const seconds = moment(start).tz('America/New_York', true).diff(moment().tz('America/New_York'), 'seconds');
    const canJoin =
      Math.abs(days) < 1 && Math.abs(hours) < 1 && (Math.abs(minutes) < 15 || (minutes < 0 && minutes > -60));

    console.log('Diff', days, hours, minutes, seconds);
    (startUrl || joinUrl || twilioRoomName) && canJoin ? setDisabled(false) : setDisabled(true);
  }, [startUrl, joinUrl, canJoinZoom, twilioRoomName]);

  const action = () => {
    if (twilioRoomName) {
      let urlType = 'room/';
      if (typeof item.communityEventType !== 'undefined') {
        urlType = item.communityEventType.name == 'Webinar' ? 'webinar/' : 'community_room/';
      }
      if (type === 'start') {
        dispatch(sessionsAction.start(id, params?.id));
      }
      if (type === 'join') {
        dispatch(sessionsAction.join(id));
      }
      setTimeout(() => {
        window.location.href = urlType + twilioRoomName;
      }, 500);
      return;
    }
    if (type === 'start') {
      window.open(startUrl, '_blank');
      dispatch(sessionsAction.start(id, params?.id));
      return;
    }

    if (type === 'join') {
      window.open(joinUrl, '_blank');
      dispatch(sessionsAction.join(id));
      return;
    }
  };

  return <Button name={name} action={action} disabled={disabled} className="btn-item" />;
};
